import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topbarBg: 'primary',
  rebaseFavFolder: {},
  filesToUpload: {
    fil: [],
    dir: [],
    comment: '',
  },
  ogDirUpload: [],
  dropFlagToggle: false,
  searchPermission: {},
  getSearchPerm: {},
  errorAlert: {},
  minimize: false,
  uploadFailedItems: [],
  favFolderGet: [],
  sendFavFolder: {},
  deleteFavsData: {},
  driveCheckoutCookieState: false,
  uploadFailedItemsServerSide: [],
  rebaseSmartFavFolderData: [],
  favSmartFolderGet: [],
  sendFavSmartFolder: {},
  notificationRequest: {},
  notificationsState: [],
  notificationPopup: false,
  readNotifications: {},
  getItemPath: {},
  itemPath: {},
  acceptInvitationisLoading: false,
  clearNotification: {},
  homeMemberLoadingState: true,
  shareLoader: { load: false, msg: false },
  singleNotificationreadState: {},
  singleNotificationReadErrorState: {
    errorFlag: false,
    status: '',
    notificationId: '',
  },
  notificationLoader: false,
  sharedFile: '',
  shareError: '',
  copyLoader: false,
  initCopy: false,
  initCut: false,
  enablePortal2fa: {},
  disablePortal2fa: {},
  get2FAPortalTrigger: {},
  portal2FAState: null,
  triggerAuthQrCode : {},
  authQRCodeState: '',
  verify2faTrigger : {},
  passwordState : '',
  disableUserProfile2fa : {},
  enableUserProfile2fa : {},
  generateUserProfileQr : {},
  twoFactorAuthenticationInstructionModalState : false,
  twoFactorAuthenticationDisablePromptState : {
    modalState : false,
    verificationCode : ''
  },
  portals2FaState : [],
  triggerPortal2FaState : {}
};
export const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    getCommonSliceData: (state) => state.payload.customizer.selectedportal,
    ChangeTopbarColor: (state, action) => {
      state.topbarBg = action.payload;
    },
    updateFilesAndDirectoriesToUpload: (state, action) => {
      state.filesToUpload = action.payload;
    },
    updateogDirUpload: (state, action) => {
      state.ogDirUpload = action.payload;
    },
    updateDropFlagToggle: (state, action) => {
      state.dropFlagToggle = action.payload;
    },
    updateSearchPermission: (state, action) => {
      state.searchPermission = action.payload;
    },
    updateGetSearchPerm: (state, action) => {
      state.getSearchPerm = action.payload;
    },
    updateCommonAlert: (state, action) => {
      state.errorAlert = action.payload;
    },
    updateMinimize: (state, action) => {
      state.minimize = action.payload;
    },
    updateUploadFailedItems: (state, action) => {
      state.uploadFailedItems.push(action.payload);
    },
    clearUploadFailedItems: (state) => {
      state.uploadFailedItems = [];
    },
    clearIndividualFailedItems: (state, action) => {
      const { index } = action.payload;
      state.uploadFailedItems.slice(index, 1);
    },
    updateFavFolderGet: (state, action) => {
      state.favFolderGet = action.payload;
    },
    updateSendFavFolders: (state, action) => {
      state.sendFavFolder = action.payload;
    },
    updateDeleteFavs: (state, action) => {
      state.deleteFavsData = action.payload;
    },
    updatedriveCheckoutCookieState: (state, action) => {
      state.driveCheckoutCookieState = action.payload;
    },
    rebasedSmartFolder: (state, action) => {
      state.rebaseSmartFavFolderData = action.payload;
    },
    updateFavSmartFolderGet: (state, action) => {
      state.favSmartFolderGet = action.payload;
    },
    updateSendFavSmartFolders: (state, action) => {
      state.sendFavSmartFolder = action.payload;
    },
    updateNotificationRequest: (state, action) => {
      state.notificationRequest = action.payload;
    },
    updateNotificationState: (state, action) => {
      state.notificationsState = action.payload;
    },
    updateNotificationPopup: (state, action) => {
      state.notificationPopup = action.payload;
    },
    updateReadNotifications: (state, action) => {
      state.readNotifications = action.payload;
    },
    updateGetItemPath: (state, action) => {
      state.getItemPath = action.payload;
    },
    updateItemPath: (state, action) => {
      state.itemPath = action.payload;
    },

    updateClearNotifications: (state, action) => {
      state.clearNotification = action.payload;
    },
    updateAcceptInvitationisLoading: (state, action) => {
      state.acceptInvitationisLoading = action.payload;
    },
    updateHomeMemberLoadingState: (state, action) => {
      state.homeMemberLoadingState = action.payload;
    },
    updateSingleNotificationreadState: (state, action) => {
      state.singleNotificationreadState = action.payload;
    },
    updateSingleNotificationReadErrorState: (state, action) => {
      state.singleNotificationReadErrorState = action.payload;
    },
    updateNotificationLoader: (state, action) => {
      state.notificationLoader = action.payload;
    },
    updateSharedFile: (state, action) => {
      state.sharedFile = action.payload;
    },
    updateShareError: (state, action) => {
      state.shareError = action.payload;
    },
    updateCopyLoader: (state, action) => {
      state.copyLoader = action.payload;
    },
    updateInitCopy: (state, action) => {
      state.initCopy = action.payload;
    },
    updateInitCut: (state, action) => {
      state.initCut = action.payload;
    },
    updateTriggerAuthQrCode: ( state,action) => {
      state.triggerAuthQrCode = action.payload
    },
    updateAuthQRCodeState: (state,action) => {
      state.authQRCodeState = action.payload
    },
    updateVerify2faTrigger: (state,action) => {
      state.verify2faTrigger = action.payload
    },
    updatePasswordState : (state,action) => {
      state.passwordState = action.payload
    },
    updateDisableUserProfile2fa : (state,action) => {
      state.disableUserProfile2fa = action.payload
    },
    updateEnableUserProfile2fa : (state,action) => {
      state.enableUserProfile2fa = action.payload
    },
    updateGenerateUserProfileQr : (state,action) => {
      state.generateUserProfileQr = action.payload
    },
    updateShareLoader: (state, action) => {
      state.shareLoader = action.payload;
    },
    updateEnablePortal2fa: (state, action) => {
      state.enablePortal2fa = action.payload;
    },
    updateDisablePortal2fa: (state, action) => {
      state.disablePortal2fa = action.payload;
    },
    updateGet2FAPortalTrigger: (state, action) => {
      state.get2FAPortalTrigger = action.payload;
    },
    updatePortal2FAState: (state, action) => {
      state.portal2FAState = action.payload;
    },
    updateTwoFactorAuthenticationInstructionModalState : (state,action) => {
      state.twoFactorAuthenticationInstructionModalState  = action.payload;
    },
    updateTwoFactorAuthenticationDisablePromptState : (state,action) => {
      state.twoFactorAuthenticationDisablePromptState = action.payload
    },
    updatePortals2FaState : (state,action) => {
      state.portals2FaState = action.payload
    },
    updateTriggerPortal2FaState : (state,action) => {
      state.triggerPortal2FaState = action.payload
    }
  },
});

export const {
  ChangeTopbarColor,
  updateSearchPermission,
  updateGetSearchPerm,
  updateCommonAlert,
  updateDropFlagToggle,
  updateFilesAndDirectoriesToUpload,
  updateMinimize,
  updateUploadFailedItems,
  clearUploadFailedItems,
  clearIndividualFailedItems,
  updateFavFolderGet,
  updateSendFavFolders,
  updateDeleteFavs,
  updatedriveCheckoutCookieState,
  rebasedSmartFolder,
  updateFavSmartFolderGet,
  updateSendFavSmartFolders,
  updateNotificationRequest,
  updateNotificationState,
  updateNotificationPopup,
  updateReadNotifications,
  updateGetItemPath,
  updateItemPath,
  updateAcceptInvitationisLoading,
  updateClearNotifications,
  updateHomeMemberLoadingState,
  updateShareLoader,
  updateSingleNotificationreadState,
  updateSingleNotificationReadErrorState,
  updateNotificationLoader,
  updateSharedFile,
  updateShareError,
  updateInitCopy,
  updateInitCut,
  updateEnablePortal2fa,
  updateDisablePortal2fa,
  updateGet2FAPortalTrigger,
  updatePortal2FAState,
  updateTriggerAuthQrCode,
  updateAuthQRCodeState,
  updateVerify2faTrigger,
  updatePasswordState,
  updateDisableUserProfile2fa,
  updateEnableUserProfile2fa,
  updateGenerateUserProfileQr,
  updateTwoFactorAuthenticationInstructionModalState,
  updateTwoFactorAuthenticationDisablePromptState,
  updatePortals2FaState,
  updateTriggerPortal2FaState
} = CommonSlice.actions;

export default CommonSlice.reducer;
